* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}
@font-face {
  font-family: "zinc";
  src: url("./fonts/zing.rust-demo-base.otf");
}



.menu-font-style {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  list-style: none;
  justify-content: center;
  text-align: center;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 700;
  font-family: "zinc" !important;
}
.ant-popover {
  position: absolute !important;
}

.scroll-indicator {
  color: white;
  position: relative;
  display: block;
  font-size: 40px;
  margin-top: 500px;
  animation: mymove 5s infinite;
}
@keyframes mymove {
  0% {
    top: 10px;
  }
  to {
    top: 40px;
  }
}

.scroll-indicator::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: " ";
  display: inline-block;
  height: 20px;
  position: relative;

  vertical-align: top;
  width: 20px;
}

.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 0px;
  /* margin-top: -130px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 750px;
}

.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 0px;
  /* margin-top: -130px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 700px;
}

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;

  width: 16px;
  height: 16px;
}

.unu {
  margin-top: 1px;
}

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.3s;
  animation-direction: alternate;

  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;

  height: 4px;
  width: 4px;
  border: 2px solid #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
  display: none;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.timer {
  inset: auto;
  margin: 0px auto;
  display: flex;
  position: relative;
  box-sizing: content-box;
  width: 100%;
  min-height: 729px;
  height: 729px;
  padding-top: 0px;
  /* padding-bottom: 1250px; */
}
.timer-space {
  height: 100vh;
  max-width: none;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center top;
  padding: 10% 0px;
  position: sticky;
  margin: auto;
  inset: 0px auto auto 0px;
  box-sizing: border-box;
  width: 1440px;
}
.timer-adjustment {
  background-color: rgba(0, 0, 0, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.875rem;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 800px;
  text-align: center;
}
.bird-nft-home__manifest__countdown-item {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 25%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 25%;
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}
.bird-nft-home__manifest__countdown-item-last {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 25%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 25%;
}

.margin-timer {
  margin: 2em 0px;
}

.countdown-container {
  text-align: center;
  margin-top: 40px;
}

.text-good {
  font-family: "Lato", sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 48.5px;
  color: white;
  font-weight: bold;
}
.text-good1 {
  font-family: "Lato", sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: white;
  font-weight: 600;
  font-size: 23.5px;
}
.border-timer {
  width: 97%;
  height: 85%;
  border: 1px solid #5d3c10 !important;
  margin-top: 12px;
}
.presale-text {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 10px;
}
.hero-mint {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  margin-top: 10px;
  z-index: 100000000;
}
.incr-decr {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
}
.add-button {
  justify-content: center;
  padding: 6px 8px;
  color: #fff;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button-style {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
.svg-button {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiTouchRipple-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}
.mint-count {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  margin: 10px;
  color: white;
}

.button-minting {
  display: flex;
  justify-content: center;
}
.MuiButton-contained {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 12px 16px;
  box-sizing: border-box;
  line-height: 1.75;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: flex-start;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 30px !important;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: letter-spacing 0.2s ease-in-out !important;
  color: #fff !important;
  background: #000 !important;
  min-width: 125px !important;
  z-index: 1000;
}
.span-labe-m {
  width: 100%;
  display: inherit;
  align-items: inherit;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border-bottom: 0px !important;
}

.styles_faq-row-wrapper__3vA1D {
  width: 100%;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  padding: 0px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #efeaa1 !important;
}
.styles_row-content-text__2sgAB {
  font-size: 23px !important;
  font-family: "Open Sans", sans-serif;
  text-align: left;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw
  svg {
  fill: var(--arrow-color, white) !important;
}
.styles_faq-row-wrapper__3vA1D {
  width: 83% !important;
  border-radius: 20px;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  /* padding-right: 3
em
; */
  font-size: 40px !important;
  font-family: "Bangers", cursive;
  text-transform: uppercase;
  font-weight: 700;
}

.styles_faq-row-wrapper__3vA1D {
  padding-left: 30px;
  padding-right: 0 !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 0em !important;
}
.faq-body {
  padding: 0px 0px 10px 0px;
}
@media (max-width: 750px) {
  .timer-adjustment {
    width: 365px !important;
  }
}

@media (max-width: 768px) {
  .banner-header {
    /* display: none !important; */
    
  }
  .banner-header1 {
    display: flex !important;
  }
  .countdown-container {
    max-width: 100%;
    /* margin: auto;
     */
    margin-top: -100px;
  }
  .MuiButton-contained {
    font-size: 22px !important;
    font-weight: bold !important;
    min-width: 50px !important;
  }
  .mint-count {
    font-size: 20px !important;
  }
  .margin-timer {
    margin-top: -46px !important;
  }
  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-content__QOGZd {
    padding-right: 20px;
  }
  .margin-letter-space {
    margin-top: 8px !important;
  }

  .timer-adjustment {
    width: 700px;
  }
  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY
    .styles_row-title-text__1MuhU {
    font-size: 15px !important;
  }
  .styles_faq-row-wrapper__3vA1D {
    width: 350px !important;
  }
  .styles_row-content-text__2sgAB {
    font-size: 14px !important;
    text-align: left;
  }
}
.mint-header1 {
  font-size: 15px !important;
  color: "white" !important;
  text-transform: uppercase;
  font-weight: "bold";
  width: 87% !important;
  background-color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 450px) {
  .mint-header {
    font-size: 19px !important;
    color: "white";
    text-transform: uppercase;
    font-family: "Sofia", sans-serif;
    font-weight: "bold";
    padding-left: 10px;
    padding-right: 10px;
    width: auto !important;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .mint-header1 {
    font-size: 15px !important;
    color: "white";
    text-transform: uppercase;
    font-weight: "bold";
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .countdown-container {
    margin-top: -64px !important;
  }
}
@media (max-width: 375px) {
  .timer-adjustment {
    width: 350px !important;
  }
}

.mint-header {
  font-size: 30px;
  color: white;
  padding-left: 11px;
  padding-right: 11px;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  width: auto;
}
.banner-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sofia", sans-serif;
}
.banner-header1 {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}
@media (max-width: 450px) {
  .bird-nft-home__manifest__countdown-item {
    padding: 6px !important;
  }
  .bird-nft-home__manifest__countdown-item-last {
    padding: 6px !important;
  }
  .text-good {
    font-size: 20px;
  }
  .text-good1 {
    font-size: 12px;
  }
}

.margin-letter-space {
  margin-top: 18px;
}

.side-bar-menu {
  justify-content: space-around;
  display: flex;
  width: 50%;
  margin-top: 13px;
}

.hide-mobile {
  visibility: hidden;
}

@media (max-width: 450px) {
  .hide-mobile {
    /* visibility: visible; */
    visibility: hidden;
  }
}

.okok {
  transform: scale(0.67);
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0) !important;
}


/*  Circle Effects */
